export const FooterLists = {
  empresas: {
    title: "para empresas",
    items: [
      { name: "Limpeza e Conservação de Ambientes" },
      { name: "Limpeza Hospitalar" },
      { name: "Jardinagem, Paisagismo e Preservação de Áreas Verdes" },
      { name: "Limpeza de Fachadas" },
      { name: "Atendimento, Recepção e Vigia em Geral" },
      { name: "Telefonista e Ascensorista" },
      { name: "Marketing Promocional" },
      { name: "Planejamento de Estoque" },
      { name: "Logística e Armazenagem" },
      { name: "Equipes de teleatendimento" },
      { name: "Serviços Administrativos" },
      { name: "Serviços de Suporte" },
    ],
  },
  condominios: {
    title: "para condomínios",
    items: [
      { name: "Limpeza e Conservação de Ambientes" },
      { name: "Jardinagem e Paisagismo" },
      { name: "Preservação de Áreas Verdes" },
      { name: "Limpeza de Fachadas" },
      { name: "Atendimento, Portaria e Vigia em Geral" },
      { name: "Ascensoristas" },
      { name: "Copeiragem e Cozinha" },
    ],
  },
}
