import { CompanyData } from "../configs"

export const whatsAppURL = (number, message) => {
  const {
    socialMedia: { whatsapp },
  } = CompanyData

  const defaultMessage =
    "Ola! acabei de visitar o site e gostaria de solicitar um orçamento. Pode me ajudar?"

  return encodeURI(
    `https://api.whatsapp.com/send?phone=${number || whatsapp}&text=${
      message || defaultMessage
    }`
  )
}
