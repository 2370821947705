export const CompanyData = {
  socialMedia: {
    facebook: "https://www.facebook.com",
    instagram: "https://www.instagram.com",
    linkedin: "https://www.linkedin.com",
    whatsapp: "21000000000",
    email: "comercial@cariocasupply.com.br",
  },
  address: {
    phone: "(21) 3031-6708",
    street: "Rua da Assembleia",
    number: "41",
    complement: " sobreloja",
    neighborhood: "Centro",
    city: "Rio de Janeiro",
    uf: "RJ",
    cep: "20.011-001",
  },
};
