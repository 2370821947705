import styled from "styled-components"
// Home components
import {
  Banner,
  Benefits,
  CallToAction,
  CustomersType,
  Facilities,
} from "./components"

const HomeComponent = styled.section`
  box-sizing: border-box;
`
export const Home = () => {
  return (
    <HomeComponent>
      <Banner />
      <CallToAction />
      <CustomersType />
      <Benefits />
      <Facilities />
    </HomeComponent>
  )
}
