import styled from "styled-components"
import { TiSocialFacebook } from "react-icons/ti"
import { FaInstagram } from "react-icons/fa"
import { RiLinkedinFill, RiWhatsappFill } from "react-icons/ri"
import { CgPhone } from "react-icons/cg"
import { MdEmail } from "react-icons/md"

// configs
import { CompanyData } from "../../configs"

// utils
import { whatsAppURL } from "../../utils"

const SocialMediaComponent = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ gap }) => gap};
  align-items: ${({ justify }) => justify};
`

const SocialMediaContent = styled.div`
  display: flex;
  gap: 8px;
  order: ${({ order }) => order};
  a {
    display: flex;
    svg {
      border-radius: 100%;
      background: ${({ iconsBgColor }) => iconsBgColor};
      padding: 8px;
      cursor: pointer;
      transition: transform 0.3s;
      box-shadow: 1px 2px 4px gray;
      :hover {
        transform: scale(1.1);
      }
    }
  }
`
const PhoneContent = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  order: ${({ order }) => order};
  font-size: 20px;
`

const Numbers = styled.div`
  display: flex;
  flex-direction: column;
  order: ${({ order }) => order};
  color: ${({ color }) => color};
  strong {
    font-size: ${({ size }) => size};
  }
`

const Address = styled.div`
  font-size: 1.6em;
  p {
    margin: 0;
    color: #2b2b2b;
    font-weight: 500;
  }
`
export const SocialMedia = ({
  iconsBgColor = "var(--menu-change-color)",
  color = "black",
  size = "15px",
  justify = "flex-end",
  havePhoneNumber = true,
  haveWhatsappNumber = true,
  haveEmail = false,
  haveAddress = false,
  gap = "10px",
  order = {
    socialMedia: 1,
    numbers: 2,
    address: 3,
  },
}) => {
  return (
    <SocialMediaComponent justify={justify} gap={gap}>
      <SocialMediaContent iconsBgColor={iconsBgColor} order={order.socialMedia}>
        <a
          target='_blank'
          rel='noreferrer'
          href={CompanyData.socialMedia.facebook}
        >
          <TiSocialFacebook color='white' size={size} />
        </a>
        <a
          target='_blank'
          rel='noreferrer'
          href={CompanyData.socialMedia.instagram}
        >
          <FaInstagram color='white' size={size} />
        </a>
        <a
          target='_blank'
          rel='noreferrer'
          href={CompanyData.socialMedia.linkedin}
        >
          <RiLinkedinFill color='white' size={size} />
        </a>
        <a
          target='_blank'
          rel='noreferrer'
          href={whatsAppURL(CompanyData.socialMedia.linkedin)}
        >
          <RiWhatsappFill color='white' size={size} />
        </a>
      </SocialMediaContent>

      <Numbers order={order.numbers} color={color} size={size}>
        {havePhoneNumber && (
          <PhoneContent>
            <CgPhone size={size} />
            <strong>{CompanyData.address.phone}</strong>
          </PhoneContent>
        )}
        {haveWhatsappNumber && (
          <PhoneContent>
            <RiWhatsappFill size={size} />
            <strong>{CompanyData.address.phone}</strong>
          </PhoneContent>
        )}
        {haveEmail && (
          <PhoneContent>
            <MdEmail size={size} />
            <strong>{CompanyData.socialMedia.email}</strong>
          </PhoneContent>
        )}
      </Numbers>
      {haveAddress && (
        <Address order={order.address}>
          <p>
            {CompanyData.address.street}, {CompanyData.address.number}
          </p>
          <p>
            {CompanyData.address.complement}, {CompanyData.address.neighborhood}
          </p>
          <p>
            {CompanyData.address.city}, {CompanyData.address.state}
          </p>
        </Address>
      )}
    </SocialMediaComponent>
  )
}
