import { useState, useCallback } from "react"
import { Link } from "react-router-dom"

import styled from "styled-components"

// Utils
import { scrollPageTo } from "../../utils"

import Logo from "../../logo.svg"

// Header components
import { Hamburger, Navigation } from "./components"

const StyledHeader = styled.header`
  position: fixed;
  top: 0;

  display: flex;
  justify-content: center;

  width: 100%;
  height: var(--header-height);

  background: white;
  box-shadow: 0 0 3px 0 gray;

  z-index: 1000;

  section {
    position: static;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
  }
`

export const Header = () => {
  const [mobileMenuIsOpen, setmobileMenuIsOpen] = useState(false)

  window.onresize = () => mobileMenuIsOpen && setmobileMenuIsOpen(false)
  const hamburgerButtonClick = useCallback(() => {
    setmobileMenuIsOpen(!mobileMenuIsOpen)
  }, [mobileMenuIsOpen, setmobileMenuIsOpen])

  return (
    <StyledHeader id='header'>
      <section className='centralizer' id='page-header'>
        <Link to='/' data-scroll='#page-header' onClick={scrollPageTo}>
          <img src={Logo} alt='carioca-supply' />
        </Link>
        <Navigation mobileMenuIsOpen={mobileMenuIsOpen} />
        <Hamburger
          isChecked={!mobileMenuIsOpen}
          eventClick={hamburgerButtonClick}
        />
      </section>
    </StyledHeader>
  )
}
