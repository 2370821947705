import { BrowserRouter, Switch, Route } from "react-router-dom"

// pages
import { Home, AboutUs, ForCompany, ForCondominium, Contact } from "../pages"

// components
import { Header, Footer } from "../components"

export const Routes = () => {
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/sobre-nos' component={AboutUs} />
        <Route path='/servicos' exact component={Home} />
        <Route path='/para-empresas' exact component={ForCompany} />
        <Route path='/para-condominios' exact component={ForCondominium} />
        <Route path='/contato' exact component={Contact} />
        <Route path='/solicitar-orcamento' exact component={Home} />
        <Route path='*' component={Home} />
      </Switch>
      <Footer />
    </BrowserRouter>
  )
}
