import especializadaImg from "../img/especializada-icon.svg"
import semRiscoImg from "../img/sem-risco-falta-icon.svg"
import tempoImg from "../img/tempo-icon.svg"

export const BenefitsCardData = [
  {
    title: "Mão de obra qualificada",
    img: especializadaImg,
  },
  {
    title: "Sem risco de falta de funcionário",
    img: semRiscoImg,
  },
  {
    title: "Mais tempo para o síndico se preocupar com outras tarefas",
    img: tempoImg,
  },

]
