import styled from "styled-components";

import Logo from "../../img/logo.svg";

// configs
import { CompanyData } from "../../../../configs";

// componentsShared
import { SocialMedia } from "../../../../componentsShared";

const LogoDataColumnComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  > img {
    width: 183px;
  }
  > .address {
    p {
      font-size: 1.3em;
    }
  }
`;
export const LogoDataColumn = () => {
  const { address } = CompanyData;
  return (
    <LogoDataColumnComponent>
      <img src={Logo} alt="Carioca Supply" />

      <div className="address">
        <p>
          {address.street}, {address.number}
        </p>
        <p>
          {address.complement}, {address.neighborhood}
        </p>
        <p>
          {address.city}/ {address.uf.toUpperCase()}
        </p>
        <p>CEP: {address.cep}</p>
      </div>
      <div className="socia-media">
        <SocialMedia
          iconsBgColor="var(--footer-color)"
          justify="flex-start"
          color="var(--footer-color)"
          size="15px"
        />
      </div>
    </LogoDataColumnComponent>
  );
};
