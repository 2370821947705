import { Link } from "react-router-dom"
import styled from "styled-components"

// services
import { NavigationList } from "../../../../services"
// utils
import { scrollPageTo } from "../../../../utils"
// componentsShared
import { SocialMedia } from "../../../../componentsShared"

const Nav = styled.nav`
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  height: 100%;
  @media (max-width: 650px) {
    position: absolute;
    top: 101%;
    left: ${({ mobileMenuIsOpen }) => (mobileMenuIsOpen ? "0%" : "-200%")};

    flex-direction: column;
    align-items: flex-start;

    width: 100%;
    height: calc(100vh - var(--header-height));
    padding: 5px 10px;

    box-shadow: 2px 0 2px 0 gray;
    background: white;
    box-sizing: border-box;
    visibility: ${({ mobileMenuIsOpen }) =>
      mobileMenuIsOpen ? "visible" : "hidden"};
    z-index: ${({ mobileMenuIsOpen }) => (mobileMenuIsOpen ? 1 : -5)};
    transition: 1s;
  }
  ul {
    flex: 1 1;
    display: flex;
    justify-content: space-evenly;
    list-style: none;

    max-width: 68.9em;
    padding: 0;

    @media (max-width: 650px) {
      flex-direction: column;
      width: fit-content;
      justify-content: start;
    }

    li {
      position: relative;
      display: flex;
      justify-content: center;

      text-transform: uppercase;
      font-weight: 500;

      @media (max-width: 650px) {
        justify-content: flex-start;
      }
      a,
      span {
        margin: 10px 5px;
        width: 100;
        font-size: 1.5em;
      }

      :hover > ul {
        visibility: visible;
        opacity: 1;
      }

      > ul {
        position: absolute;
        top: 100%;

        display: flex;
        flex-direction: column;

        padding: 0;

        opacity: 0;
        visibility: hidden;
        box-shadow: 0 0 5px 0 gray;

        background: white;
        list-style: none;

        transition: opacity 0.5s;
        @media (max-width: 650px) {
          top: 0%;
          left: 100%;
          border-left: 1px solid black;
          box-shadow: none;
        }
        li {
          position: relative;
          display: flex;
          justify-content: start;

          text-transform: capitalize;
          white-space: nowrap;

          :hover {
            :after {
              width: 100%;
            }
          }
          :after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;

            width: 0;
            height: 100%;

            background: rgba(100, 100, 100, 0.3);
            transition: width 0.5s;
          }
          :hover {
            color: var(--menu-gray-color);
          }
          a {
            width: 100%;
            height: 100%;
          }
        }
      }

      cursor: pointer;
      a,
      span {
        margin: 0;
        padding: 10px;
        text-decoration: none;
        color: var(--menu-gray-color);

        z-index: 10;
        :hover {
          color: var(--menu-change-color);
        }
      }
    }
  }
`

export const Navigation = ({ mobileMenuIsOpen = true }) => {
  return (
    <Nav mobileMenuIsOpen={mobileMenuIsOpen}>
      <ul>
        <li>
          <Link to='/' data-scroll='#page-header' onClick={scrollPageTo}>
            Home
          </Link>
        </li>
        {NavigationList.map(
          ({ name, link, dataScroll, children, whatsApp }, index) => {
            return (
              <li key={index}>
                {link && (
                  <Link
                    to={link}
                    data-scroll={dataScroll}
                    onClick={scrollPageTo}
                  >
                    {name}
                  </Link>
                )}
                {whatsApp && (
                  <a href={whatsApp} target='_blank' rel='noreferrer'>
                    {name}
                  </a>
                )}
                {!whatsApp && !link && <span>{name}</span>}
                {children && (
                  <ul>
                    {children.map(({ name, link, dataScroll }, index) => (
                      <li key={index}>
                        <Link
                          data-scroll={dataScroll}
                          onClick={scrollPageTo}
                          to={link}
                        >
                          {name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )
          }
        )}
      </ul>
      <SocialMedia
        haveWhatsappNumber={false}
        color='var(--menu-change-color)'
      />
    </Nav>
  )
}
