import styled from "styled-components"

const HamburgerComponent = styled.button`
  position: relative;
  display: none;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;

  margin: 0;
  background: none;
  border: 0;
  outline: none;
  cursor: pointer;

  transition: transform 0.3s;
  @media (max-width: 650px) {
    display: flex;
  }
  :hover {
    transform: scale(1.1);
  }

  span {
    position: relative;
    width: 100%;
    height: 5px;
    background: var(--menu-blue-color);
    border-radius: 10px;
    transform: ${({ isChecked }) => (isChecked ? "-0" : "rotate(-45deg)")};
    transition: transform 0.3s;

    :after,
    :before {
      content: "";

      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;

      border-radius: 10px;
      background: var(--menu-blue-color);
      transform: ${({ isChecked }) => (isChecked ? "-0" : "rotate(90deg)")};
      transition: transform 0.3s;
    }
    :before {
      top: ${({ isChecked }) => (isChecked ? "-10px" : "0px")};
    }
    :after {
      top: ${({ isChecked }) => (isChecked ? "10px" : "0")};
    }
  }
`

export const Hamburger = ({ isChecked = false, eventClick }) => {
  return (
    <HamburgerComponent isChecked={isChecked} onClick={() => eventClick()}>
      <span></span>
    </HamburgerComponent>
  )
}
