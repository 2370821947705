import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  :root{
    --header-height: 144px;

    /* responsive-wrap */
    --menu-wrap: 650px;

    /* colors */
    --menu-blue-color: #014665;
    --menu-gray-color: #404A68;
    --menu-green-color: #014665;
    --menu-change-color: #5bc0be;
    
    --footer-background: #0E2732;
    --footer-color: #98AAB2;
    --footer-copy-bg: #014665;

    /*home components */
    --callToAction-background: #F5F5F5;
    --callToAction-color: #353535;

    --CustomersType-CardToRight-background: #014665;
    --CustomersType-CardToLeft-background: #0E2732;
    --CustomersType-customersCallToAction-background: #5BC0BE;

    --Facilities-background: #F5F5F5;
    --Facilities-font-color: #0E2732;

    /* sharedComponents */
    --card-font-color: #0E2732;
    
    --callToAction2-font-color: #0E2732;
    --callToAction2-background: #5BC0BE;

    /* aboutUs components */
    --about-us-main-background: #F5F5F5;
    --about-us-main-color: #2B2B2B;
    --about-us-main-button: #5BC0BE;
    --about-us-carousel-background: #F5F5F5;

    /* contact components */
    --contact-title-font-color: #014665;
    --contact-form-background: #014665;
    --contact-form-label-color: #83A1AE;
    
    scroll-behavior: smooth;
  }

  body{
    margin: 0;
    padding: 0;
    padding-top: var(--header-height);
    box-sizing: border-box;

    font-family: 'Roboto', sans-serif;
    font-size: 62.5%;

    @media(max-width:950px){
      font-size: 52.5%;
    }
    @media(max-width:830px){
      font-size: 42.5%;
    }

  }

  ul{
    margin: 0;
    padding: 0px;
  }
  a{
    color: unset;
    text-decoration: none;
  }

  .centralizer{
    max-width: 1144px;
    width: 90vw;

    margin: 0 auto;
    box-sizing: border-box;
  }
  
  .banner-centralizer{
    max-width: 1928px;
    width: 100%;
    margin: 0 auto;
  }
  .title-2{
    margin: 0%;
    color: var(----Facilities-font-color);
    font-size: 3em;
    text-align: center;
    text-transform: uppercase;
  }
  .text-2{
    margin: 0%;
    text-align: center;
    font-size: 1.5em;
  }
  .services {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    gap: 31px;
    h2{
      font-size: 2.5em;
      color: #014665;
      font-weight: 600;
      text-transform: uppercase;
      margin: 0;
    }
    p{
      margin: 0;

      font-size: 1.8em;
      color: #0E2732;
    }
    ul{
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      padding-left: 1em;
      font-size: 2em;
      font-weight: bold;
    }

  }
`
