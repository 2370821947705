import styled from "styled-components"
import AliceCarousel from "react-alice-carousel"

import "react-alice-carousel/lib/alice-carousel.css"
import AguLogo from "./img/Agu-logo.png"
import AnvisaLogo from "./img/Anvisa-logo.png"
import PetrobrasLogo from "./img/Petrobras-logo.png"
import ArborLogo from "./img/Arbor-logo.png"
import CefetRJLogo from "./img/CefetRJ-logo.png"
import ICMBioLogo from "./img/ICMBio-logo.png"
import MinisterioDaSaudeLogo from "./img/Ministerio-da-Saude-logo.png"
import ReceitaFederalLogo from "./img/Receita-Federal-logo.png"
import TelTelecomLogo from "./img/Tel-Telecom-logo.png"

const CarouselContainer = styled.div`
  background: var(--about-us-carousel-background);
  cursor: grab;
  h2 {
    margin: 0%;
    color: var(----Facilities-font-color);
    font-size: 2.5em;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 30px;
  }
`
const CarouselCard = styled.div`
  display: flex;
  align-items: center;

  width: 200px;
  height: 200px;
  padding: 20px;
  box-sizing: border-box;
  user-select: none;
  img {
    width: 100%;
  }
`

const itemsList = [
  {
    name: "Agu",
    img: AguLogo,
  },
  {
    name: "Anvisa",
    img: AnvisaLogo,
  },
  {
    name: "Petrobras",
    img: PetrobrasLogo,
  },
  {
    name: "Arbor",
    img: ArborLogo,
  },
  {
    name: "CefetRJ",
    img: CefetRJLogo,
  },
  {
    name: "ICMBio",
    img: ICMBioLogo,
  },
  {
    name: "MinisterioDaSaude",
    img: MinisterioDaSaudeLogo,
  },
  {
    name: "ReceitaFederal",
    img: ReceitaFederalLogo,
  },
  {
    name: "TelTelecom",
    img: TelTelecomLogo,
  },
]
const items = itemsList.map(({ name, img }) => {
  return (
    <CarouselCard className='item'>
      <img src={img} alt={name} />
    </CarouselCard>
  )
})

export const PartnersCarousel = () => {
  const responsive = {
    600: { items: 2 },
    875: { items: 3 },
    2000: { items: 4 },
  }
  return (
    <CarouselContainer>
      <div className='centralizer'>
        {/* <h2>Quem já atendemos:</h2> */}
        {/* <h2>Já trabalhamos com:</h2> */}
        <h2>Clientes atendidos por nós:</h2>
        <AliceCarousel
          mouseTracking
          keyboardNavigation
          items={items}
          responsive={responsive}
          infinite
          disableDotsControls
          disableButtonsControls
          autoWidth
          autoPlay
          animationDuration='1000'
          autoPlayInterval='2000'
        />
      </div>
    </CarouselContainer>
  )
}
