import styled from "styled-components"

// footer services
import { FooterLists } from "./services"

// footer components
import { LogoDataColumn, ServicesColumn, SiteMapColumn } from "./components"

const FooterComponent = styled.footer`
  display: flex;
  flex-direction: column;

  color: var(--footer-color);
  background: var(--footer-background);
  box-sizing: border-box;
  h3 {
    font-size: 1.6em;
    text-transform: uppercase;
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding-left: 15px;
    text-transform: capitalize;
    li {
      font-size: 1.4em;
    }
  }
  p {
    margin: 0;
  }
`
const MainFooter = styled.main`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  padding: 80px 0 70px 0;
  > div {
    flex: 1 1 23%;
    min-width: 230px;
  }
`
const CopyContent = styled.div`
  flex: 0 0 58px;
  display: flex;
  background: var(--footer-copy-bg);
  .centralizer {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4em;
  }
`
export const Footer = () => {
  const { empresas, condominios } = FooterLists

  return (
    <FooterComponent>
      <MainFooter className='centralizer'>
        <LogoDataColumn />
        <SiteMapColumn />
        <ServicesColumn list={empresas} />
        <ServicesColumn list={condominios} />
      </MainFooter>
      <CopyContent>
        <div className='centralizer'>
          <p>
            Copyright (©) <strong>Carioca Supply</strong>,{" "}
            {new Date().getFullYear()}.
          </p>
          <p>
            Desenvolvido por{" "}
            <strong>
              <a
                href='http://www.agenciamojo.com.br/'
                target='_blank'
                rel='noreferrer'
              >
                Agência Mojo
              </a>
            </strong>
          </p>
        </div>
      </CopyContent>
    </FooterComponent>
  )
}
