import reducaoCustosImg from "../img/reducao-custos-icon.svg"
import otimizacaoServicosImg from "../img/otimizacao-servicos-icon.svg"
import focoImg from "../img/foco-icon.svg"
import reducaoImg from "../img/reducao-custos-icon.svg"
import especializadaImg from "../img/especializada-icon.svg"
import legalImg from "../img/legal-icon.svg"

export const FacilitiesCardsData = [
  {
    title: "Redução e Controle de Custos",
    img: reducaoCustosImg,
  },
  {
    title: "Otimização dos serviços e Aumento da Produtividade",
    img: otimizacaoServicosImg,
  },
  {
    title: "Foco nos Negócios Principais da Empresa",
    img: focoImg,
  },
  {
    title: "Redução de Riscos",
    img: reducaoImg,
  },
  {
    title: "Ferramentas de Tecnologia e Mão de Obra Especializada",
    img: especializadaImg,
  },
  {
    title: "Maior Segurança Legal",
    img: legalImg,
  },
]
