import { useState, useEffect } from "react"
import styled from "styled-components"

// componentShared
import { SocialMedia, Button } from "../../componentsShared"

// Contact components
import { EmailSendedModal } from "./components"

// contact utils
import { FormSubmitEvent } from "./utils"

const ContactComponent = styled.section`
  padding: 75px 0;
  .centralizer {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
  }
`
const TextContainer = styled.div`
  flex: 1 1 350px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  box-sizing: border-box;
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  h1 {
    font-size: 6em;
    color: var(--contact-title-font-color);
    margin: 0;
  }
  p {
    margin: 0;
    font-size: 1.6em;
    font-weight: 500;
  }
`
const FormContainer = styled.div`
  flex: 1 1 400px;
  display: flex;
  flex-direction: column;
  gap: 2.3em;

  padding: 3.5em;

  background: var(--contact-form-background);
  border-radius: 10px;
  box-sizing: border-box;
  h3 {
    color: white;
    font-size: 2.4em;
    margin: 0;
  }
  form {
    display: flex;
    flex-wrap: wrap;
    gap: 2em;

    box-sizing: border-box;
    label {
      flex: 1 1 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-size: 1.2em;
      color: var(--contact-form-label-color);
      input,
      textarea,
      select {
        position: relative;
        display: flex;

        padding: 0.8em;
        font-size: 1.5em;
        margin: 0;
        box-sizing: border-box;
        resize: none;
        :focus {
          outline: 1px solid black;
        }
        :after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 1em;
          background: red;
        }
      }
    }
    .button {
      justify-self: flex-end;
    }
    .phone-mail {
      flex: 1 1 auto;
      display: flex;
      gap: 2em;
      flex-wrap: wrap;
      label:first-child {
        flex: 1 1 30px;
      }
      label:last-child {
        flex: 1 1 30px;
      }
    }
  }
`
export const Contact = () => {
  const [emailModalIsOpen, setEmailModalIsOpen] = useState(false)

  const openSendEmailModal = () => setEmailModalIsOpen(true)
  const closeSendEmailModal = () => setEmailModalIsOpen(false)

  const formSubmit = (event) => {
    const configs = {
      doIfMessageSended: [openSendEmailModal],
      doIfMessageNotSended: [],
    }
    FormSubmitEvent(event, configs)
  }
  useEffect(() => window.scrollTo(0, 0), [])
  useEffect(() => {
    emailModalIsOpen &&
      setTimeout(() => {
        closeSendEmailModal()
      }, 2000)
  }, [emailModalIsOpen])

  const socialMediaConfig = {
    justify: "flex-start",
    haveEmail: "true",
    haveAddress: "true",
    color: "var(--contact-title-font-color)",
    gap: "25px",
    order: {
      socialMedia: -1,
      numbers: -2,
      address: -1,
    },
  }
  return (
    <ContactComponent>
      <div className='centralizer'>
        <TextContainer>
          <TitleContainer>
            <h1>Vamos conversar!</h1>
            <p>
              Entre em contato conosco através do Telefone, WhatsApp, E-mail ou
              Redes Sociais. Solicite-nos um orçamento!
            </p>
          </TitleContainer>
          <SocialMedia {...socialMediaConfig} />
        </TextContainer>

        <FormContainer>
          <h3>Envie uma mensagem</h3>
          <form onSubmit={formSubmit}>
            <label>
              Tipo de contato:*
              <select name='about'>
                <option value='Orçamento'>Orçamento</option>
                <option value='Comercial'>Comercial</option>
                <option value='Financeiro'>Financeiro</option>
                <option value='RH'>RH</option>
              </select>
            </label>

            <label>
              Nome completo:*
              <input type='text' name='name' required placeholder='Digite...' />
            </label>

            <div className='phone-mail'>
              <label>
                Telefone:*
                <input
                  type='phone'
                  name='phone'
                  required
                  placeholder='(DDD)00000-0000'
                />
              </label>
              <label>
                email:*
                <input
                  type='email'
                  name='email'
                  required
                  placeholder='seuemail@email.com.br'
                />
              </label>
            </div>

            <label>
              Mensagem:
              <textarea cols='30' rows='6' name='message'></textarea>
            </label>
            <Button Type='submit'>Enviar</Button>
          </form>
          <EmailSendedModal
            emailModalIsOpen={emailModalIsOpen}
            closeSendEmailModal={closeSendEmailModal}
          />
        </FormContainer>
      </div>
    </ContactComponent>
  )
}
