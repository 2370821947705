// GlobalStyle
import { GlobalStyle } from "./styles/GlobalStyle"

// routes
import { Routes } from "./Routes"

function App() {
  return (
    <>
      <GlobalStyle />
      <Routes />
    </>
  )
}

export default App
