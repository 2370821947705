import styled from "styled-components"

const ModalSection = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
  visibility: ${({ visibility }) => visibility === "false" && "hidden"};
`
const ModalBg = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
`
const ModalContainer = styled.div`
  position: absolute;
  width: 250px;
  min-height: 150px;
  padding: 10px;
  display: flex;
  align-items: center;

  background: white;
  box-sizing: border-box;
`
const CloseButton = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s;

  ::before,
  ::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 3px;
    background: red;
  }
  ::before {
    transform: rotate(45deg);
  }
  ::after {
    transform: rotate(-45deg);
  }
  :hover {
    transform: scale(1.2);
  }
`
const MessageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 2.3em;
    text-align: center;
  }
`
export const EmailSendedModal = ({ emailModalIsOpen, closeSendEmailModal }) => {
  return (
    <ModalSection visibility={emailModalIsOpen.toString()}>
      <ModalBg onClick={closeSendEmailModal} />
      <ModalContainer>
        <CloseButton onClick={closeSendEmailModal} />
        <MessageContainer>
          <p>Messagem enviada com sucesso!</p>
        </MessageContainer>
      </ModalContainer>
    </ModalSection>
  )
}
