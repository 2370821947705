import { Link } from "react-router-dom"
import styled from "styled-components"
import MainImg from "./img/main-img.png"

// sharedComponents
import { Button } from "../../../../componentsShared"

const MainElement = styled.main`
  padding: 116px 0;
  background: var(--about-us-main-background);
  .centralizer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 55px;
  }
`
const ImageSide = styled.div`
  flex: 1 1 250px;
  display: flex;
  align-items: flex-start;
  img {
    width: 100%;
  }
`
const TextSide = styled.div`
  flex: 1 1 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2em;

  p {
    margin: 0;

    font-size: 1.8em;
    color: var(--about-us-main-color);
  }
`

export const Main = () => {
  return (
    <MainElement>
      <div className='centralizer'>
        <ImageSide>
          <img src={MainImg} alt='Sobre nós' />
        </ImageSide>
        <TextSide>
          <p>
            A Carioca Supply é uma das empresas do Grupo VS Participações, que
            atua no mercado de Terceirização de Serviços desde 2011, tendo
            atendido diversos clientes tanto na iniciativa privada, quanto em
            órgãos públicos.
          </p>
          <p>
            Com atuação em todo o território nacional, oferecemos soluções para
            bancos, condomínios, indústrias e empresas de diversos portes e nos
            mais variados segmentos .
          </p>
          <p>
            Contamos com uma equipe treinada e capacitada, com excelência
            profissional e agilidade para desempenhar um serviço de qualidade,
            buscando sempre a satisfação de nossos clientes. Oferecemos todos os
            materiais necessários, produtos de qualidade e equipamentos
            profissionais com tecnologia de ponta para a execução das nossas
            atividades.
          </p>
          <Link to='/contato'>
            <Button color='var(--about-us-main-button)'>
              Entre em contato conosco
            </Button>
          </Link>
        </TextSide>
      </div>
    </MainElement>
  )
}
