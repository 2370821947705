export const NavigationList = [
  {
    name: "Sobre Nós",
    link: "/sobre-nos",
    dataScroll: "#page-header",
  },
  {
    name: "Serviços",
    // link: "servicos",
    dataScroll: "#servicos",
    children: [
      {
        name: "Para condomínios",
        link: "para-condominios",
        dataScroll: "#header",
      },
      {
        name: "Para empresas",
        link: "para-empresas",
        dataScroll: "#header",
      },
    ],
  },
  {
    name: "Contato",
    link: "contato",
    dataScroll: "#header",
  },
  // {
  //   name: "Solicitar Orçamento",
  //   whatsApp: whatsAppURL(),
  //   dataScroll: "#solicitar-orcamento",
  // },
]
