import { sendEmail } from "../../../api/sendEmail"
export const FormSubmitEvent = (event, configs) => {
  event.preventDefault()
  const { name, email, phone, message, about } = event.target
  const emailData = {
    name: name.value,
    email: email.value,
    phone: phone.value,
    message: message.value,
    about: about.value,
  }

  const doIfMessageSended = () => {
    event.target.reset()
    configs?.doIfMessageSended.map((currentFunction) => currentFunction())
  }
  const doIfMessageNotSended = () => {
    configs?.doIfMessageNotSended.map((currentFunction) => currentFunction())
  }

  sendEmail(emailData, doIfMessageSended, doIfMessageNotSended)
}
