import { useEffect } from "react"
import styled from "styled-components"
import { MdKeyboardArrowDown } from "react-icons/md"
import BannerBg from "./img/banner-bg.png"

// componentsShared
import { MainBanner, CallToAction2 } from "../../componentsShared"

// utils
import { scrollPageTo } from "../../utils"

// services
import { servicesListByType } from "../../services"

const ForCompanyComponent = styled.section``

const Banner = styled.div`
  display: flex;
  .centralizer {
    position: absolute;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 100%;
  }
`

export const ForCompany = () => {
  const { company } = servicesListByType

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <ForCompanyComponent>
      <Banner>
        <MainBanner img={BannerBg}>
          <div className='centralizer'>
            <h1>Soluções em Terceirização de Serviços Para Empresas</h1>
            <MdKeyboardArrowDown
              size='7em'
              data-scroll='#for-company-text'
              onClick={scrollPageTo}
            />
          </div>
        </MainBanner>
      </Banner>
      <div className='centralizer services' id='for-company-text'>
        <div>
          <p>
            Investir em terceirização de serviços é uma decisão estratégica que
            libera a empresa para se dedicar melhor em sua atividade-fim,
            impactando positivamente em seus resultados.
          </p>
          <p>
            Com profissionais treinados e qualificados, a Carioca Supply oferece
            diversas soluções em terceirização de mão de obra para empresas de
            vários seguimentos, buscando sempre a satisfação de nossos clientes.
          </p>
        </div>
        <h2>Conheça nossas principais soluções para empresas:</h2>
        <ul>
          {company.map((service, index) => (
            <li key={index}>{service.name}</li>
          ))}
        </ul>
      </div>
      <CallToAction2 />
    </ForCompanyComponent>
  )
}
