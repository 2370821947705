import styled from "styled-components"

const ServicesColumnComponent = styled.div``
export const ServicesColumn = ({ list }) => {
  return (
    <ServicesColumnComponent>
      <h3>{list.title}</h3>
      <ul>
        {list.items.map((currentItem, index, array) => (
          <li key={index}>
            {currentItem.name}
            {index === array.length - 1 ? "." : ";"}
          </li>
        ))}
      </ul>
    </ServicesColumnComponent>
  )
}
