import { Link } from "react-router-dom"
import styled from "styled-components"
import bgImg from "./img/bg.jpg"

// componentsShared
import { Button, MainBanner } from "../../../../componentsShared"

const BannerComponent = styled.section`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  width: 95%;
  max-width: 1144px;
  height: 100%;

  color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: start;
  h1 {
    flex: 0 0 auto;
    margin: 0.1em 0 0.4em 0;
    font-size: 4.8em;
    text-align: start;
  }
  h3 {
    margin: 0;
    padding: 1em;

    background: var(--menu-green-color);
    font-size: 1.8em;
  }
  .buttons {
    display: flex;
    gap: 1.5em;
    flex-wrap: wrap;
  }
`
export const Banner = () => {
  return (
    <MainBanner img={bgImg}>
      <BannerComponent id='banner'>
        <h3>Facilities Services</h3>
        <h1>Soluções em Terceirização de Serviços</h1>
        <div className='buttons'>
          <Button haveScroll scrollTarget='#servicos'>
            Nossos serviços
          </Button>
          <Link to='contato'>
            <Button>Solicitar Orçamento</Button>
          </Link>
        </div>
      </BannerComponent>
    </MainBanner>
  )
}
