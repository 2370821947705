import styled from "styled-components"

// componentsShared
import { CallToAction2 } from "../../../../componentsShared"

// CustomersType services
import { CustomerTypesCadList } from "./services/CustomerTypesCardList"
// CustomersType components
import { Card } from "./components"

const CustomerTypeComponent = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
`

export const CustomersType = () => {
  return (
    <CustomerTypeComponent id='servicos'>
      {CustomerTypesCadList.map((currentCard, index) => (
        <Card currentCard={{ ...currentCard, index }} key={index} />
      ))}
      <CallToAction2 id='solicitar-orcamento' />
    </CustomerTypeComponent>
  )
}
