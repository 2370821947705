import { Link } from "react-router-dom"
import styled from "styled-components"
// componentsShared
import { Button } from "../../componentsShared"

const CallToAction2Component = styled.div`
  background: var(--callToAction2-background);
  color: var(--callToAction2-font-color);
  display: flex;
  .centralizer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;

    padding: 50px 0;
    box-sizing: border-box;
    h2 {
      font-size: 4.8em;
      margin: 0;
      text-align: center;
    }
  }
`
export const CallToAction2 = ({ id = "solicitar-orcamento" }) => {
  return (
    <CallToAction2Component id={id}>
      <div className='centralizer'>
        <h2>Solicite um orçamento</h2>
        <Link to='/contato'>
          <Button color='var(--Facilities-font-color)'>Solicitar</Button>
        </Link>
      </div>
    </CallToAction2Component>
  )
}
