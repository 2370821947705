import companyImg from "../img/company.jpg"
import condominiumImg from "../img/condominium.jpg"

export const CustomerTypesCadList = [
  {
    title: "para condomínios",
    text: "Condomínios limpos garantem uma melhor qualidade de vida para os moradores, além de gerar valor para o imóvel, elevando sua cotação no mercado.",
    buttonText: "Saiba mais",
    buttonPath: "/para-condominios",
    img: condominiumImg,
    background: "var(--CustomersType-CardToRight-background)",
  },
  {
    title: "para empresas",
    text: " Investir em terceirização de serviços é uma decisão estratégica que libera a empresa para se dedicar melhor em sua atividade-fim, impactando positivamente em seus resultados, independente de seu seguimento.",
    buttonText: "Saiba mais",
    buttonPath: "/para-empresas",
    img: companyImg,
    background: "var(--CustomersType-CardToLeft-background)",
  },
]
