import { useEffect } from "react"
import styled from "styled-components"
import { MdKeyboardArrowDown } from "react-icons/md"
import BannerBg from "./img/banner-bg.png"

// componentsShared
import { MainBanner, CallToAction2 } from "../../componentsShared"

// utils
import { scrollPageTo } from "../../utils"

// services
import { servicesListByType } from "../../services"

const ForCondominiumComponent = styled.section``

const Banner = styled.div`
  display: flex;
  .centralizer {
    position: absolute;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 100%;
  }
`

export const ForCondominium = () => {
  const { condominium } = servicesListByType

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <ForCondominiumComponent>
      <Banner>
        <MainBanner img={BannerBg}>
          <div className='centralizer'>
            <h1>Soluções em Terceirização de Serviços Para Condominios</h1>
            <MdKeyboardArrowDown
              size='7em'
              data-scroll='#for-condominium-text'
              onClick={scrollPageTo}
            />
          </div>
        </MainBanner>
      </Banner>
      <div className='centralizer services' id='for-condominium-text'>
        <div>
          <p>
            Condomínios limpos garantem uma melhor qualidade de vida para os
            moradores, além de gerar valor para o imóvel, elevando sua cotação
            no mercado.
          </p>
          <p>
            Com profissionais treinados e qualificados, a Carioca Supply oferece
            diversas soluções em terceirização de mão de obra para Condomínios
            de vários portes, buscando sempre a satisfação de nossos clientes e
            o bem-estar dos moradores.
          </p>
        </div>
        <h2>Conheça nossas principais soluções para condomínios:</h2>
        <ul>
          {condominium.map((service, index) => (
            <li key={index}>{service.name}</li>
          ))}
        </ul>
      </div>
      <CallToAction2 />
    </ForCondominiumComponent>
  )
}
