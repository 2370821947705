import { Link } from "react-router-dom"
import styled from "styled-components"

// services
import { NavigationList } from "../../../../services"

const SiteMapColumnComponent = styled.div`
  ul {
    gap: 5px;

    padding: 0;

    list-style: none;
  }
`
export const SiteMapColumn = () => {
  return (
    <SiteMapColumnComponent>
      <h3>Instittucional</h3>
      <ul>
        {NavigationList.map(({ link, dataScroll, name, whatsApp }, index) => (
          <li key={index}>
            {link && (
              <Link to={link} data-scroll={dataScroll}>
                {name}
              </Link>
            )}
            {whatsApp && (
              <a href={link} target='_blank' rel='noreferrer'>
                {name}
              </a>
            )}
            {/* {!whatsApp && !link && <a>{name}</a>} */}
          </li>
        ))}
      </ul>
    </SiteMapColumnComponent>
  )
}
