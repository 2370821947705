import styled from "styled-components"

// Facilities services
import { FacilitiesCardsData } from "./services/FacilitiesCardsData"

// componentsShared
import { Cards } from "../../../../componentsShared"

const FacilitiesComponent = styled.section`
  padding: 80px 0;
  background: var(--Facilities-background);
  .centralizer {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 50px;
  }
`
const FacilitiesTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  h2 {
    margin: 0;
  }
  p {
    margin: 0;
  }
`
const FacilitiesCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
`
export const Facilities = () => {
  return (
    <FacilitiesComponent>
      <div className='centralizer'>
        <FacilitiesTitle>
          <h2 className='title-2'>
            Porque você deveria contratar Carioca Supply para sua empresa?
          </h2>
          <p className='text-2'>
            A terceirização gera vantagens como a possibilidade de eliminar
            custos e garantir mais competitividade e flexibilidade no dia a dia
            do ambiente empresarial.
          </p>
        </FacilitiesTitle>

        <FacilitiesCardContainer>
          {FacilitiesCardsData.map((currentCard, index) => (
            <Cards currentCard={currentCard} key={index} />
          ))}
        </FacilitiesCardContainer>
      </div>
    </FacilitiesComponent>
  )
}
