import { useEffect } from "react"
// AboutUs components
import { Main, PartnersCarousel } from "./components"
export const AboutUs = () => {
  useEffect(() => window.scrollTo(0, 0), [])
  return (
    <div>
      <Main />
      <PartnersCarousel />
    </div>
  )
}
