import styled from "styled-components"

const CardComponent = styled.div`
  flex: 0 1 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  box-shadow: 0px 4px 10px #00000030;
  padding: 54px;
  box-sizing: border-box;
  h5 {
    text-align: center;
    font-size: 1.6em;
    color: var(--card-font-color);
    margin: 0;
  }
`

export const Cards = ({ currentCard }) => {
  const { title, img } = currentCard
  return (
    <CardComponent>
      <img src={img} alt={title} />
      <h5>{title}</h5>
    </CardComponent>
  )
}
