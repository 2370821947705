import { Link } from "react-router-dom"
import styled from "styled-components"

// componentsShared
import { Button } from "../../../../../../componentsShared"

const CardComponent = styled.div`
  background: ${({ background }) => background};

  display: flex;
  flex-direction: column;
  align-items: center;
  > .out-container {
    display: flex;
    justify-content: ${({ direction }) =>
      direction ? "flex-start" : "flex-end"};
    max-width: 1344px;
    width: 100vw;
    transform: ${({ direction }) =>
      direction ? "translateX(-13%)" : "translateX(13%)"};
    @media (max-width: 750px) {
      transform: initial;
    }

    > .inner-container {
      display: flex;
      flex-direction: ${({ direction }) => (direction ? "row-reverse" : "row")};
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      > .text-container {
        flex: 1 1 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: ${({ direction }) =>
          direction ? "flex-start" : "flex-end"};
        gap: 15px;
        padding: 30px;

        text-align: ${({ direction }) => (direction ? "start" : "end")};
        color: white;

        @media (max-width: 750px) {
          align-items: center;
        }

        h3 {
          margin: 0;
          font-size: 4.8em;
          text-transform: uppercase;
        }
        p {
          margin: 0;
          font-size: 1.6em;
        }
      }
      > .img-container {
        flex: 1 1 350px;
        display: flex;
        justify-content: center;
        img {
          width: 100%;
          /* max-width: 90vw; */
        }
      }
    }
  }
`
export const Card = ({ currentCard }) => {
  const { title, text, buttonText, img, background, index, buttonPath } =
    currentCard
  const isRightCard = index % 2

  return (
    <CardComponent background={background} direction={isRightCard}>
      <div className='out-container'>
        <div className='inner-container'>
          <div className='text-container'>
            <h3>{title}</h3>
            <p>{text}</p>
            <Link to={buttonPath}>
              <Button>{buttonText}</Button>
            </Link>
          </div>
          <div className='img-container'>
            <img src={img} alt='empresa' />
          </div>
        </div>
      </div>
    </CardComponent>
  )
}
