import styled from "styled-components"

// componentsShared
import { Cards } from "../../../../componentsShared"
// Benefits services
import { BenefitsCardData } from "./services"

const BenefitsComponent = styled.section`
  padding: 80px 0;
  .centralizer {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
`

const BenefitsTitleContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`
const BenefitsCardContent = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
`
export const Benefits = () => {
  return (
    <BenefitsComponent>
      <div className='centralizer'>
        <BenefitsTitleContent>
          <h2 className='title-2'>
            benefícios ao contratar Carioca Supply para condomínios
          </h2>
          <p className='text-2'>
            O setor de terceirização de serviços vem crescendo mais a cada ano,
            devido a quantidade de pontos positivos em terceirizar serviços dos
            mais variados seguimentos para empresas. Há cada dia mais,
            empreendedores vem entendendo a importância de considerar a
            contratação de terceirarão de mão de obra, tornando a empresa capaz
            de focar em sua atividade principal, com a garantia de um trabalho
            com profissionais qualificados e menos burocracia.
          </p>
        </BenefitsTitleContent>
        <BenefitsCardContent>
          {BenefitsCardData.map((currentCard, index) => (
            <Cards currentCard={currentCard} key={index} />
          ))}
        </BenefitsCardContent>
      </div>
    </BenefitsComponent>
  )
}
