import emailjs from "emailjs-com"
import { init } from "emailjs-com"
init("user_u7shaH2Tm7zlcc0hHX8LU")
export const sendEmail = async (
  emailData,
  doIfMessageSended,
  doIfMessageNotSended
) => {
  return emailjs.send("service_tbeo0zj", "template_fowfqsc", emailData).then(
    (response) => {
      doIfMessageSended()
    },
    (error) => {
      console.log("FAILED...", error)
      doIfMessageNotSended()
    }
  )
}
