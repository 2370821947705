const ServicesList = [
  {
    name: "Limpeza e Conservação de Ambientes",
    type: ["company", "condominium"],
  },
  {
    name: "Limpeza Hospitalar",
    type: ["company"],
  },
  {
    name: "Jardinagem, Paisagismo e Preservação de Áreas Verdes",
    type: ["company", "condominium"],
  },
  {
    name: "Limpeza de Fachadas",
    type: ["company", "condominium"],
  },
  {
    name: "Atendimento, Recepção e Vigia em Geral",
    type: ["company"],
  },
  {
    name: "Telefonista e Ascensorista",
    type: ["company"],
  },
  {
    name: "Marketing Promocional",
    type: ["company"],
  },
  {
    name: "Planejamento de Estoque",
    type: ["company"],
  },
  {
    name: "Logística e Armazenagem",
    type: ["company"],
  },
  {
    name: "Equipes de teleatendimento",
    type: ["company"],
  },
  {
    name: "Serviços Administrativos",
    type: ["company"],
  },
  {
    name: "Serviços de Suporte",
    type: ["company"],
  },
  {
    name: "Atendimento, Portaria e Vigia em Geral",
    type: ["condominium"],
  },
  {
    name: "Ascensoristas",
    type: ["condominium"],
  },
  {
    name: "Copeiragem e Cozinha",
    type: ["condominium"],
  },
]
export const servicesListByType = ServicesList.reduce(
  (servicesListByType, currentService) => {
    currentService.type.forEach((service) => {
      servicesListByType[service] = servicesListByType[service] || []
      servicesListByType[service].push(currentService)
    })
    return servicesListByType
  },
  {}
)
