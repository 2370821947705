import styled from "styled-components"
import { MdKeyboardArrowRight } from "react-icons/md"

import { scrollPageTo } from "../../utils"

const ButtonComponent = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;

  padding: 0.5em 1.5em;

  border: ${({ Color }) => `3px solid ${Color}`};
  border-radius: 3em;

  background: none;
  color: ${({ Color }) => Color};
  font-weight: 500;
  font-size: clamp(10px, 1.8rem, 18px);
  text-transform: capitalize;

  cursor: pointer;
  transition: 0.3s transform;

  svg {
    transform: scale(2);
  }
  :hover {
    transform: translateX(10px);
  }
`

export const Button = ({
  children,
  color = "white",
  Type,
  haveScroll = false,
  scrollTarget,
}) => {
  const onclickEvent = (event) => {
    haveScroll && scrollPageTo(event)
  }
  return (
    <ButtonComponent
      Color={color}
      type={Type}
      onClick={onclickEvent}
      data-scroll={scrollTarget}
    >
      {children}
      <MdKeyboardArrowRight />
    </ButtonComponent>
  )
}
