import styled from "styled-components"

const BannerComponent = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  width: 100%;
  overflow: hidden;
  img {
    width: cover;
  }
  h1 {
    flex: 1 1 auto;

    display: flex;
    align-items: center;
    font-size: 4.8em;
    color: white;
    text-align: center;
    margin: 0;
  }
  svg {
    color: white;
    transition: transform 0.3s;
    cursor: pointer;
    :hover {
      transform: translateY(10%);
    }
  }
`
export const MainBanner = ({ img, children }) => {
  return (
    <BannerComponent className='banner-centralizer'>
      <img src={img} alt='Banner' />
      {children}
    </BannerComponent>
  )
}
