export const scrollPageTo = (event) => {
  const target = event.target.dataset.scroll
  const element = document.querySelector(target)
  if (element) {
    const elementOffSetTop =
      element.offsetTop >= 150 ? element.offsetTop - 150 : 0

    window.scrollTo(0, elementOffSetTop)
  }
}
