import { Link } from "react-router-dom"
import styled from "styled-components"

// componentsShared
import { Button } from "../../../../componentsShared"

const Section = styled.section`
  display: flex;
  justify-content: center;
  padding: 50px 0;
  text-align: center;

  background: var(--callToAction-background);
  color: var(--callToAction-color);

  .centralizer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;

    position: relative;
    font-size: 1.8em;
    box-sizing: border-box;
    overflow: hidden;
    h2 {
      margin: 0;
    }
  }
`

export const CallToAction = () => {
  return (
    <Section id='call-to-action'>
      <div className='centralizer'>
        <h2>
          Com dedicação e eficácia, ajudamos sua empresa focar no que faz de
          melhor, aumentando sua produtividade, otimizando recursos e reduzindo
          custos operacionais.
        </h2>
        <Link to='/contato'>
          <Button color={"var(--menu-change-color)"}>Saiba mais</Button>
        </Link>
      </div>
    </Section>
  )
}
